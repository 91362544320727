
















































import {
  dispatchGetCitySettings,
  dispatchPutCitySettings,
} from "@/store/admin/actions";
import { readCityId } from "@/store/main/getters";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class CityGeneralSettings extends Vue {
  private loaded: boolean = false;

  private ticketImageRequired: boolean = false;
  private ticketLocationRequired: boolean = false;
  private ticketUserDataRequired: boolean = false;
  private ticketInactiveAtCreation: boolean = false;

  public async mounted() {
    await this.loadCitySettings();
  }

  private async loadCitySettings() {
    let settings = await dispatchGetCitySettings(
      this.$store,
      readCityId(this.$store)
    );

    this.ticketImageRequired = settings.ticket_image_required;
    this.ticketLocationRequired = settings.ticket_location_required;
    this.ticketUserDataRequired = settings.ticket_user_data_required;
    this.ticketInactiveAtCreation = settings.ticket_inactive_at_creation;
    this.loaded = true;
  }

  private async save() {
    let cityId = readCityId(this.$store);
    await dispatchPutCitySettings(this.$store, {
      cityId: cityId,
      citySettings: {
        city_id: cityId,
        ticket_image_required: this.ticketImageRequired,
        ticket_location_required: this.ticketLocationRequired,
        ticket_user_data_required: this.ticketUserDataRequired,
        ticket_inactive_at_creation: this.ticketInactiveAtCreation,
      },
    });

    this.loaded = false;
    await this.loadCitySettings();
  }
}
