






































































































import { ITicketType } from "@/interfaces";
import { Vue, Component } from "vue-property-decorator";
import {
  dispatchGetTicketTypes,
  dispatchPostTicketTypes,
} from "@/store/admin/actions";
import { readCityId } from "@/store/main/getters";

@Component
export default class TicketTypeEditor extends Vue {
  private dialog: boolean = false;
  private dialogTitle: string = "Kategorie Hinzufügen";
  private dialogInput: string = "";
  private isTypeDialog: boolean = false;
  private isAdding: boolean = false;
  private currentTypeIdx: number = 0;
  private currentSubTypeIdx: number = 0;

  private ticketTypeState: ITicketType[] = [];

  private abortAdding() {
    this.dialog = false;
  }

  private commitAdding() {
    if (this.isAdding) {
      if (this.isTypeDialog) {
        this.ticketTypeState.push({ title: this.dialogInput, sub_types: [] });
      } else {
        this.ticketTypeState[this.currentTypeIdx].sub_types.push({
          title: this.dialogInput,
        });
      }
    } else {
      if (this.isTypeDialog) {
        this.ticketTypeState[this.currentTypeIdx].title = this.dialogInput;
      } else {
        this.ticketTypeState[this.currentTypeIdx].sub_types[
          this.currentSubTypeIdx
        ].title = this.dialogInput;
      }
    }
    this.dialog = false;
  }

  private editType(typeIdx: number) {
    this.dialogTitle = "Kategorie Bearbeiten";
    this.dialogInput = this.ticketTypeState[typeIdx].title;
    this.currentTypeIdx = typeIdx;
    this.isTypeDialog = true;
    this.isAdding = false;
    this.dialog = true;
  }

  private editSubType(typeIdx: number, subTypeIdx: number) {
    this.dialogTitle = "Sub-Kategorie Bearbeiten";
    this.dialogInput =
      this.ticketTypeState[typeIdx].sub_types[subTypeIdx].title;
    this.currentTypeIdx = typeIdx;
    this.currentSubTypeIdx = subTypeIdx;
    this.isTypeDialog = false;
    this.isAdding = false;
    this.dialog = true;
  }

  private addType() {
    this.dialogTitle = "Kategorie Hinzufügen";
    this.dialogInput = "";
    this.isTypeDialog = true;
    this.isAdding = true;
    this.dialog = true;
  }

  private addSubType(typeIdx: number) {
    this.dialogTitle = "Sub-Kategorie Hinzufügen";
    this.dialogInput = "";
    this.isTypeDialog = false;
    this.isAdding = true;
    this.currentTypeIdx = typeIdx;
    this.dialog = true;
  }

  private removeType(typeIdx: number) {
    this.$delete(this.ticketTypeState, typeIdx);
  }

  private removeSubType(typeIdx: number, subTypeIdx: number) {
    this.$delete(this.ticketTypeState[typeIdx].sub_types, subTypeIdx);
  }

  public async mounted() {
    this.ticketTypeState = await dispatchGetTicketTypes(
      this.$store,
      readCityId(this.$store)
    );
  }

  private async save() {
    const newData = await dispatchPostTicketTypes(this.$store, {
      cityId: readCityId(this.$store),
      ticketTypes: this.ticketTypeState,
    });

    if (newData) {
      this.ticketTypeState = newData;
    }
  }
}
