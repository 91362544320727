























import { Component, Vue } from "vue-property-decorator";
import TicketTypeEditor from "@/components/TicketTypeEditor.vue";
import CityGeneralSettings from "@/components/CityGeneralSettings.vue";
import LegalTextsEditor from "@/components/LegalTextsEditor.vue";

@Component({
  components: {
    TicketTypeEditor,
    CityGeneralSettings,
    LegalTextsEditor,
  },
})
export default class CitySettings extends Vue {}
