













































import {
  dispatchGetCityLegalTexts,
  dispatchPutCityLegalTexts,
} from "@/store/admin/actions";
import { readCityId } from "@/store/main/getters";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class LegalTextsEditor extends Vue {
  private loaded: boolean = false;

  private siteNotice: string = "";
  private privacyDeclaration: string = "";
  private barrierDeclaration: string = "";

  public async mounted() {
    await this.loadCityLegalTexts();
  }

  private async loadCityLegalTexts() {
    let legalTexts = await dispatchGetCityLegalTexts(
      this.$store,
      readCityId(this.$store)
    );

    this.privacyDeclaration = legalTexts.privacy_declaration;
    this.barrierDeclaration = legalTexts.barrier_declaration;
    this.siteNotice = legalTexts.site_notice;
    this.loaded = true;
  }

  private async save() {
    let cityId = readCityId(this.$store);
    await dispatchPutCityLegalTexts(this.$store, {
      cityId: cityId,
      cityLegalTexts: {
        privacy_declaration: this.privacyDeclaration,
        barrier_declaration: this.barrierDeclaration,
        site_notice: this.siteNotice, 
      },
    });

    this.loaded = false;
    await this.loadCityLegalTexts();
  }
}
